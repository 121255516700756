import LoginForm from "../../Components/Auth/LoginForm";

function Login() {
  return (
    <div className="login-page">
      <LoginForm />
    </div>
  );
}
export default Login;
