import Welcome from "../../Components/Dashboard/Welcome";
function DashboardPage() {
  return (
    <>
      <Welcome />
    </>
  );
}

export default DashboardPage;
