import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import CookieManager from "../../Utils/cookieManager";
import { LOGIN_API, LOGOUT_API, REFRESH_TOKEN_API } from "../../api";

const initialState = {
  accessToken: null,
  refreshToken: null,
  user: null,
  isLoading: false,
  error: null,
};

export const login = createAsyncThunk("auth/login", async (credentials, { rejectWithValue }) => {
  try {
    const response = await axios.post(LOGIN_API, credentials);
    return response.data;
  } catch (error) {
    return rejectWithValue({
      error: error.response.data.error,
      status: error.response.status,
    });
  }
});

export const logout = createAsyncThunk("auth/logout", async (_, { getState, rejectWithValue }) => {
  try {
    const { auth } = getState();
    await axios.put(LOGOUT_API, {
      refreshToken: auth.refreshToken,
      accessToken: auth.accessToken,
    });
    CookieManager.remove("accessToken");
    CookieManager.remove("refreshToken");
    delete axios.defaults.headers.common["Authorization"];
    return null;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const refreshToken = createAsyncThunk("auth/refreshToken", async (_, { getState, rejectWithValue }) => {
  try {
    const { auth } = getState();
    const response = await axios.put(REFRESH_TOKEN_API, {
      refreshToken: auth.refreshToken,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens(state, action) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    clearTokens(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accessToken = action.payload.token.accessToken;
        state.refreshToken = action.payload.token.refreshToken;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.accessToken = null;
        state.refreshToken = null;
        state.user = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.accessToken = null;
        state.refreshToken = null;
        state.user = null;
      });
  },
});

export const { setTokens, clearTokens, setUser } = authSlice.actions;

export default authSlice.reducer;
